/**
 File to declare constants related to Optimizely experiments
 */

const PROJECT_ID = '11355970984' // production
const TEST_PROJECT_ID = '17294405044' // stage

/**
 * We have set up proxying for optimizely through our CDN
 * @readonly
 */
const OPTIMIZELY_URL = 'https://optimizely-cdn.ethoslife.com/js'

export const OPTIMIZELY_SCRIPT_SRC = `${OPTIMIZELY_URL}/${PROJECT_ID}.js`
export const OPTIMIZELY_SCRIPT_SRC_DEV = `${OPTIMIZELY_URL}/${TEST_PROJECT_ID}.js`

// Used to trigger Daily Premium pricing on BoF Package Select
// Also used to trigger Quick Scan treatment on PreInterview /q/recommendation
export const DAILY_DOLLARS_COOKIE = {
  NAME: 'ETHOS_DAILY_DOLLARS_TOF',
  EW_VALUE: 'DAILY_EW',
}

// Matching LP copy to ad campaign copy
// https://ethoslife.atlassian.net/browse/GC-2183
// https://app.optimizely.com/v2/projects/15340850549/experiments/24139470024/variations
export const LP_CAMPAIGN_1 = {
  EXPERIMENT_KEY: 'lpCampaign8888464272',
  VARIATIONS: {
    CONTROL: 'original',
    TREATMENT: 'TREATMENT',
  },
  HEADING: 'Instant term life insurance',
}

/**
 * Experiment to simulate a landing page that looks like the start of the main app
 */

export const ASTA = {
  VARIATIONS: {
    TREATMENT: 'TREATMENT',
  },
  COOKIE_NAME: 'asta', // same as experiment key but want to be explicit
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_v3_3_gc-5243/rules/development/edit/wills_v3_3_gc-5243
export const WILLS_V3_4 = {
  EXPERIMENT_KEY: 'wills_v3_4_gc-5331',
  VARIATIONS: {
    CONTROL: 'control',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
    TREATMENT_4: 'treatment_4',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_new_meta_lp-gc-5369/rules/development/edit/wills_new_meta_lp-gc-5369
export const WILLS_META_AFFILIATE_LP = {
  EXPERIMENT_KEY: 'wills_v3_4_gc-5481',
  VARIATIONS: {
    CONTROL: 'control',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
    TREATMENT_4: 'treatment_4',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/question_template_preinterview/rules/development
export const QUESTION_TEMPLATE = {
  EXPERIMENT_KEY: 'question_template_preinterview',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
}

export const WILLS_NEW_HERO_SECTION = {
  EXPERIMENT_KEY: 'gc-5806-wills-new-hero-section',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

export const SINGLE_CTA_EXPERIMENT = {
  EXPERIMENT_KEY: 'single_cta_experiment',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_2: 'treatment_2',
  },
  COOKIE: {
    NAME: 'single_cta',
    VALUE: 'true',
  },
}

export const HEADLINE_RAPID_TEST = {
  EXPERIMENT_KEY: 'headline-rapid-test-gc-5474',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
    VARIANT_4: 'variant_4',
    VARIANT_5: 'variant_5',
    VARIANT_6: 'variant_6',
  },
}

export const TRUSTAGE_LANDING_PAGE = {
  EXPERIMENT_KEY: 'trustage_landing_page-gc-5024',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
  MODULE_EXP_KEY: 'trustageLandingPage-off',
}
